import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/login";
import Verify from "./components/verify";
import Oops from "./components/oops";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Verify />} />
        <Route path="*" element={<Oops />} />
      </Routes>
    </div>
  );
}

export default App;
