import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "../css/login.css";
import "../css/cards.css";
import "../css/verify.css";
import logo from "../assets/snagm-logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//-- coupon code results:  VALID, USED, INVALID, ERROR

export default function Verify() {
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [ready, setReady] = useState(false);
  const [codeStatus, setCodeStatus] = useState("INVALID");
  const [showCodeStatus, setShowCodeStatus] = useState(false);
  const [token, setToken] = useState("");
  const [processing, setProcessing] = useState(false);
  const [couponTitle, setCouponTitle] = useState("");
  const [couponTag, setCouponTag] = useState("");
  const [couponTerms, setCouponTerms] = useState("");
  const [couponImage, setCouponImage] = useState("");

  const handleCodeChange = (event) => {
    if (event.target.value.length > 4) return;
    let newCode = event.target.value.toUpperCase();
    setCouponCode(newCode);
    if (newCode.length > 3) {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("snagm");
    navigate("/login");
  };

  const handleOK = () => {
    setShowCodeStatus(false);
    setCodeStatus("INVALID");
    setCouponCode("");
    setReady(false);
  };

  const handleVerify = async () => {
    if (couponCode === "OOOO") return handleLogout();
    setProcessing(true);
    const resultData = await getCodeResult();
    setCodeStatus(resultData.result);
    if (resultData.result === "VALID") {
      const couponData = resultData.coupon;
      setCouponTitle(couponData.title);
      setCouponTag(couponData.tag);
      setCouponTerms(couponData.terms);
      setCouponImage(couponData.webURL);
    }
    setShowCodeStatus(true);
    setProcessing(false);
  };

  const getCodeResult = async () => {
    let finalData = {
      result: "INVALID",
    };
    const bodyData = {
      token: token,
      code: couponCode,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch("https://us-central1-snagm-b6068.cloudfunctions.net/validate", {
      method: "POST",
      body: bodyString,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        finalData = data;
      })
      .catch((error) => {
        return finalData;
      });
    return finalData;
  };

  //-- on each load, check that we have a token in local storage and load it
  useEffect(() => {
    let token = localStorage.getItem("snagm");
    if (token) {
      setToken(token);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Container fluid className="home-container">
      <Row>
        <Col className="d-flex justify-content-center">
          <Image src={logo} className="main-logo" />
        </Col>
      </Row>
      <Row>
        <Col className="welcome-title">
          <h1>Ready!</h1>
        </Col>
      </Row>
      <Row>
        <Col className="main-title">
          <h5>Check coupon codes here.</h5>
        </Col>
      </Row>
      {codeStatus === "INTERNET" && (
        <Row>
          <Alert className="network-text" variant="danger">
            INTERNET PROBLEM: Please check your connection and try again.
          </Alert>
        </Row>
      )}
      <Row>
        <Col className="dash-card instruction-phone">
          <Row>
            <h4 className="d-flex justify-content-center">Coupon Code:</h4>
          </Row>
          <Row className="d-flex justify-content-center">
            <Form.Control
              className="phone-input v-top-5"
              type="text"
              value={couponCode}
              onChange={handleCodeChange}
            />
          </Row>
          {showCodeStatus && (
            <React.Fragment>
              <Row className="alert-row">
                <Alert
                  className="alert-style"
                  variant={codeStatus === "VALID" ? "success" : "danger"}
                >
                  {codeStatus}
                </Alert>
              </Row>
              {codeStatus === "VALID" && (
                <React.Fragment>
                  <Image fluid src={couponImage} />
                  <Row className="v-top-10 padded-row-lg">
                    <h4 className="d-flex justify-content-center">
                      {couponTitle}
                    </h4>
                  </Row>
                  <Row className="padded-row-lg">
                    <h5 className="d-flex justify-content-center">
                      {couponTag}
                    </h5>
                  </Row>
                  <Row className="v-top-10 padded-row-lg">
                    <p className="d-flex justify-content-center">
                      {couponTerms}
                    </p>
                  </Row>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {showCodeStatus ? (
            <Row className="button-row">
              <Button variant="primary" size="lg" onClick={() => handleOK()}>
                OK
              </Button>
            </Row>
          ) : (
            <Row className="button-row">
              {!processing && (
                <Button
                  disabled={!ready}
                  variant="primary"
                  size="lg"
                  onClick={() => handleVerify()}
                >
                  Verify
                </Button>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}
