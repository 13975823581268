import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export default function OopsPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const goLogin = () => {
      navigate("/login");
    };
    goLogin();
  }, [navigate]);

  return (
    <main style={{ padding: "1rem" }}>
      <p>OOPS! There's nothing here!</p>
    </main>
  );
}
