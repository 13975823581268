import React, { useState } from "react";
import { useNavigate } from "react-router";
import "../css/login.css";
import "../css/cards.css";
import logo from "../assets/snagm-logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const alertMessage =
  "Invalid username or password. Please check and try again.";

export default function Login() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [ready, setReady] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleNameChange = (event) => {
    let thisValid = false;
    setName(event.target.value);
    if (event.target.value.length >= 1) {
      setValidName(true);
      thisValid = true;
    } else {
      setValidName(false);
      thisValid = false;
    }
    if (thisValid && validPassword) {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const handlePasswordChange = (event) => {
    let thisValid = false;
    if (event.target.value.length > 12) return;
    setPassword(event.target.value);
    if (event.target.value.length >= 6) {
      setValidPassword(true);
      thisValid = true;
    } else {
      setValidPassword(false);
      thisValid = false;
    }
    if (thisValid && validName) {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const loginAPI = async () => {
    let finalData = "";
    const bodyData = {
      name: name,
      password: password,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch(
      "https://us-central1-snagm-b6068.cloudfunctions.net/loginFunction",
      {
        method: "POST",
        body: bodyString,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        finalData = data;
      })
      .catch((error) => {
        finalData = "INVALID";
      });
    if (finalData === "INVALID") return finalData;
    return finalData.token;
  };

  const handleSubmit = async () => {
    setShowAlert(false);
    setProcessing(true);
    const token = await loginAPI();
    if (!token) {
      setShowAlert(true);
      setProcessing(false);
      return;
    }
    if (token === "INVALID") {
      setShowAlert(true);
      setProcessing(false);
      return;
    }
    localStorage.setItem("snagm", token);
    setProcessing(false);
    navigate("/");
  };

  return (
    <Container fluid className="home-container">
      <Row>
        <Col className="d-flex justify-content-center">
          <Image src={logo} className="main-logo" />
        </Col>
      </Row>
      <Row>
        <Col className="welcome-title">
          <h1>Welcome!</h1>
        </Col>
      </Row>
      <Row>
        <Col className="main-title">
          <h5 className="d-flex justify-content-center">
            Sign in to validate coupons.
          </h5>
        </Col>
      </Row>
      <Row>
        <Col className="dash-card instruction-phone">
          <Row>
            <h4 className="d-flex justify-content-center">Login Name:</h4>
          </Row>
          <Row className="d-flex justify-content-center">
            <Form.Control
              className="phone-input v-top-5"
              type="text"
              value={name}
              onChange={handleNameChange}
            />
          </Row>
          <Row className="v-top-30">
            <h4 className="d-flex justify-content-center">Password:</h4>
          </Row>
          <Row className="d-flex justify-content-center">
            <Form.Control
              className="phone-input v-top-5"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Row>
          {showAlert && (
            <Row className="alert-row">
              <Alert className="margin-reset" variant="danger">
                {alertMessage}
              </Alert>
            </Row>
          )}
          <Row className="v-top-30 padded-row-lg">
            {!processing && (
              <Button
                disabled={!ready}
                variant="primary"
                size="lg"
                onClick={() => handleSubmit()}
              >
                Sign In
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
